/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import undoIcon from '../../../assets/images/Undo-Arrow.svg';
import refreshIcon from '../../../assets/images/refresh.png';
import { getUpdatedParams, getSelectedInfo } from '../../../utils/commonRingUtils';
import AppTabs from '../../components/AppTabs';
import If from '../../components/If';
import Loader from '../../components/Loader';
import MyGallery from '../../components/MyGallery';
import { ITab, ITabs } from '../../types/customizer';
import { getPageData } from './Pages';
import DesktopSidebar from "../../components/Sidebar/DesktopSidebar";
import MobileSidebar from "../../components/Sidebar/MobileSidebar";
import leftArrow from '../../../assets/images/left-chevrolet.svg';
import CartIcon from '../../../assets/images/cart-icon.svg';
import ReverseIcon from '../../../assets/images/contrast.png';
import listIcon from "../../../assets/images/list-icon.svg";

import { useRingCustomizerContext } from '../../context/RingCustomizerContext';
import RingCustomizerHOC from '../../components/RingCustomizerHOC';
import RingCustomizerLayout from '../../layouts/RingCustomizerLayout';
import { APP_STYLES, DEFAULT_STYLES } from '../../../utils/basic';
import AppModal from '../../components/AppModal';

const RingCustomizer = () => {
  const [pages, setPages] = useState<ITabs>([]);
  const [key, setKey] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false)
  const [myHistory, setMyHistory] = useState<string[]>(() => {
    const list = sessionStorage.getItem('myHistory');
    return list ? JSON.parse(list) : [];
});

const location = useLocation();
const navigate = useNavigate();

  const {
    product,
    params,
    url,
    type,
    isQueryHandling,
    handleAddToCart,
    handleDone,
    subpath,
    storeData,
    isLoading
  } = useRingCustomizerContext();

  useEffect(() => {
    const { subpath, productHandle } = params.pathParams;
    if(productHandle && subpath === 'customize') {
      viewedProduct(productHandle);
    }
  }, [params.pathParams]);

  useEffect(() => {
    if(product) {
      const pages = product ? getPageData({
        product,
        type,
        subpath,
        storeData,
        ring_type: params.pathParams.ring_type,
        isBuildFromScratch: params?.queryParams?.isBuildFromScratch === 'true'
      }) : [];
      const activekey = getActiveTab(pages);
      setPages(pages);
      if(!key) {
        setKey(activekey);
      }
    }
  }, [product])

  useEffect(() => {
    if(product) {
      /**
       * Check currenturl with data.ring_options_url, if diffreent load `data.ring_options_url`
       */
      const ringOptionUrl = product.ring_options_url;
      if(ringOptionUrl !== url.ring_url) {
        navigateToUrl(ringOptionUrl, true); // Replace last URL
      } else {
        const { productHandle } = params.pathParams;
        if(!productHandle) {
          /**
           * Set klavio tracking whenever new data with following criteria
           * 1. data gets changes
           * 2. ringOptionUrl === url.ring_url
           * 3. when there is no `productHandle`
           */
          console.log('>>>>>>KLAVIO TRACKING');
          addViewedKlavioTracking();
        }
      }
    }
  }, [product])

  const addViewedKlavioTracking = () => {
    if(!product) {
      return;
    }
    var _learnq: any = (window as any)._learnq || [];
    const item = {
      Brand: 'Emblem Custom',
      Name: product.description,
      ProductID: product.sku,
      URL: window.location.href,
      price: product.price,
      ImageURL: `${process.env.REACT_APP_ASSET_URL}${product.images[0]}`
    }
    _learnq.push(['track', 'Viewed Product', item]);
  }

  const fetchProduct = (handle: string) => {
    return fetch(`/products/${handle}.js`).then(async res => {
      if(res.ok) {
        return res.json();
      } else {
        const text = await res.text();
        throw new Error(text);
      }
    });
  }

  const viewedProduct = async (productHandle: string) => {
    if(process.env.REACT_APP_ENVIRONMENT !== 'production') {
      return;
    }

    const product = await fetchProduct(productHandle);
    console.log({ response: product });

    var _learnq: any = (window as any)._learnq || [];
    const item = {
      Brand: 'Emblem Custom',
      Name: product.title,
      ProductID: product.id,
      URL: window.location.href,
      price: product.price / 100,
      ImageURL: `https://${product.featured_image}`,
      CompareAtPrice: product.compare_at_price || 0,
    }
    _learnq.push(['track', 'Viewed Product', item]);
  }

  const handleUrlChange = (params: { urlParams: any, queryParams: any }) => {
    let URL = '';
    if(type === 1) {
      const { urlParams: { profile, width, ring_materials }, queryParams } = params;
      const searchString = qs.stringify(queryParams);
      URL = `/rings/profiles/${profile}/widths/${width}/ring_materials/${ring_materials}?${searchString}`;
    } else if(type === 2) {
      const { urlParams: { styles, ring_materials, color_arrangements, stone_quantities, variants }, queryParams } = params;
      const searchString = qs.stringify(queryParams);
      URL = `/women/rings/styles/${styles}/ring_materials/${ring_materials}/color_arrangements/${color_arrangements}/stone_quantities/${stone_quantities}/variants/${variants}?${searchString}`
    } else if (type === 3) {
      const { urlParams: { ring_type, ring_materials}, queryParams } = params;
      const searchString = qs.stringify(queryParams);
      URL = `/rings/${ring_type}/ring_materials/${ring_materials}?${searchString}`;
  }
    addHistory();
    navigateToUrl(URL, false); // push new URL
  }

  const navigateToUrl = (URL: string, replace = true) => {
    const { pathprefix, subpath } = params.pathParams;
    let navigateUrl = URL;
    if(pathprefix && subpath) {
      navigateUrl = `/${pathprefix}/${subpath}${URL}`;
    } else if(subpath){
      navigateUrl = `/${subpath}${URL}`;
    }

    if(isQueryHandling) {
      navigateUrl = `${location.pathname}?ring_url=${encodeURIComponent(navigateUrl)}`;
    }

    const otherQueryString = new URLSearchParams(params.otherQueryParams).toString();
    if(otherQueryString) {
      navigateUrl += `&${otherQueryString}`;
    }
    navigate(navigateUrl, { replace: replace }); // After URL is navigated it calls a useffect of location dependencies
  }

  const handleUpdate = (tobeUpdated: any) => {
    const paramsData = {
      urlParams: params.pathParams,
      queryParams: params.queryParams
    }
    const updatedParams = getUpdatedParams(tobeUpdated, paramsData)
    handleUrlChange(updatedParams);
  }

  const getActiveTab = (items: ITab[]) => {
    const selectedTab = items.find(tab => tab.isSelected);
    let key = '';
    if(selectedTab) {
      key = selectedTab.eventKey;
    } else {
      key = items?.[0]?.eventKey || '';
    }
    return key;
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleActiveTab = (key: string, toggleVisibility: boolean) => () => {
    setKey(key);
    if(toggleVisibility) {
      toggleOpen();
    }
  }

  const addHistory = () => {
    const URL = url.ring_url;
    const list = [...myHistory, URL];
    sessionStorage.setItem('myHistory', JSON.stringify(list));
    setMyHistory(list);
  };

  const removeHistory = () => {
    const cloned = myHistory.slice();
    cloned.pop();
    sessionStorage.setItem('myHistory', JSON.stringify(cloned));
    setMyHistory(cloned);
  };

  const handleReset = () => {
    navigateToUrl(myHistory[0]);
    sessionStorage.removeItem('myHistory');
    setMyHistory([]);
  };

  const handleUndo = () => {
    if(myHistory.length) {
      navigate(-1);
      removeHistory();
    }
  }

  const renderTopBar = () => {
  	const styles = APP_STYLES[subpath] || DEFAULT_STYLES;
    return (
      <>
        <If condition={['customize'].includes(subpath)} >
          <div className='tw-flex tw-items-center tw-justify-between tw-px-3 tw-h-13 tw-w-full tw-top-0 tw-bg-secondary-bg-color sm:tw-hidden secondary-font-family'>
            <div className='tw-pl-1.5 tw-text-black tw-underline tw-text-sm' onClick={handleDone}>
              <img src={leftArrow} alt="right_arrow" className="tw-inline-block tw-mb-1" /> Done
            </div>
            <button className="tw-bg-red tw-text-white tw-px-9 tw-py-2 tw-rounded-full" onClick={handleAddToCart}>Add to Cart - {product?.price}</button>
            <a href="/cart"><img src={CartIcon} alt="cart_icon" className="tw-pl-3 tw-w-10" /></a>
          </div>
        </If>
        <If condition={!['customize'].includes(subpath)}>
          <div className="tw-m-2 sm:tw-hidden">
        	  <a href='/' className="tw-w-56 tw-inline-block">
              <img className="brand-logo" alt="brand_logo"/>
            </a>
          </div>
          <If condition={styles.enableDefaultDone}>
            <div className='tw-flex tw-items-center tw-justify-center tw-px-3 tw-h-13 tw-w-full tw-top-0 tw-bg-secondary-bg-color sm:tw-hidden'>
              <button className="!tw-bg-brand-color !tw-text-white tw-px-9 tw-py-2 secondary-font-family tw-rounded-tertiary-border-radius" onClick={handleDone}>
                {['johnAtencio', 'johnAtencio-stage'].includes(subpath) ? 'DONE' : 'Done'} - {product?.price}
              </button>
            </div>
          </If>
          <If condition={['tqDiamonds', 'tqDiamonds-stage'].includes(subpath)}>
            <button className='!tw-bg-brand-color !tw-text-white tw-px-9 tw-py-2 tw-rounded-tertiary-border-radius tw-font-medium tw-mx-auto sm:tw-hidden secondary-font-family' onClick={handleAddToCart}>
              Add to Cart
            </button>
          </If>
        </If>
      </>)
  }

  const handleEngravingReversePattern = () => {
    const { selectedOption } = engravingInfo;
    if (selectedOption.is_reverse_selected) {
        handleUpdate({ engraving_pattern: selectedOption.sku });
    } else {
        handleUpdate({ engraving_pattern: `${selectedOption.sku}REV` });
    }
};

const handleLogoReversePattern = () => {
    const { selectedOption } = SignetEngravingInfo;
    if (selectedOption.is_reverse_selected) {
        handleUpdate({ signet_engraving: selectedOption.sku });
    } else {
        handleUpdate({ signet_engraving: `${selectedOption.sku}REV` });
    }
};

const selectedPage = pages.find(item => item.eventKey === key);
const engravingInfo = key === 'engraving' ? getSelectedInfo(selectedPage) : null;
const SignetEngravingInfo = key === 'logo' || key === 'outsideFeature_1' ? getSelectedInfo(selectedPage) : null;

  return (<div className="tw-flex tw-flex-col app-container">
    <RingCustomizerLayout />
    {renderTopBar()}
    <div className="main_container tw-flex-1 tw-flex tw-flex-wrap secondary-font-family">
        <DesktopSidebar pages={pages} selectedTab={key} handleActiveTab={handleActiveTab} price={product?.price}/>
        <div className="tw-flex-1 tw-flex tw-flex-col tw-w-0 tw-h-full">
          {/* Gallery */}
          <div className={clsx("gallery_container tw-relative tw-pb-1 tw-pt-1 lg:tw-pt-10", {'sm:tw-hidden': ['summaryInfo', 'summary'].includes(key)})}>
            <MyGallery images={product?.images || []}/>
            <div className="tw-absolute tw-bottom-1 tw-text-center tw-cursor-pointer tw-left-3 sm:tw-left-6 lg:tw-left-20 tw-flex xs:tw-flex-col lg:tw-flex-row xs:tw-gap-2 lg:tw-gap-5 secondary-font-family">
              <If condition={myHistory.length}>
                    <div onClick={handleUndo} role="presentation">
                        <img src={undoIcon} alt="undo_icon" className="tw-inline-block width-20" />
                        <div className="tw-underline tw-text-gray-600 tw-text-sm">Undo</div>
                    </div>
                    <div onClick={handleReset} role="presentation">
                        <img src={refreshIcon} alt="undo_icon" className="tw-inline-block tw-w-6" />
                        <div className="tw-underline tw-text-gray-600 tw-text-sm tw-m-0.5">Reset</div>
                    </div>
              </If>
              <If condition={key === 'engraving' && engravingInfo?.selectedOption?.has_inside_reversed_option}>
                  <div onClick={handleEngravingReversePattern} role="presentation">
                    <img src={ReverseIcon} alt="undo_icon" className="tw-inline-block tw-w-6" />
                    <div className="tw-underline tw-text-gray-600 tw-text-sm tw-m-0.5">Reverse <br />Pattern</div>
                  </div>
                </If>
              <If condition={(key === 'logo' || key === 'outsideFeature_1') && SignetEngravingInfo?.selectedOption?.has_outside_reversed_option}>
                <div onClick={handleLogoReversePattern} role="presentation">
                  <img src={ReverseIcon} alt="undo_icon" className="tw-inline-block tw-w-6" />
                  <div className="tw-underline tw-text-gray-600 tw-text-sm tw-m-0.5">Reverse <br />Pattern</div>
                </div>
              </If>
            </div>

          </div>
          {/* Customize container */}
          <div className={clsx(`tw-relative customizer_container tw-flex-1 tw-flex tw-flex-col ${key}`)}>
            <div id="main-content" className={clsx('tw-flex-1', { 'hidden': open })}>
              {pages.length > 0 &&
                  <AppTabs
                      tabs={pages}
                      tabStyle="single"
                      onChange={handleUpdate}
                      isFetching={isLoading}
                      activeKey={key}
                      onChangeParentActiveKey={setKey}
                      images={product?.images}
                  />}
            </div>
            {!open && <div className="tw-absolute tw-left-3 tw-top-3 tw-cursor-pointer sm:tw-hidden" onClick={toggleOpen}>
              <img src={listIcon} alt="list_icon" className="tw-inline"/>
              {/* <span className='tw-text-base tw-font-bold tw-ml-3 tw-text-primary-color'>All Steps</span> */}
            </div>}
            <AppModal size='lg' isOpen={open} onClose={toggleOpen} hideCloseIcon={true}>
              <MobileSidebar
                pages={pages}
                selectedTab={key}
                handleActiveTab={handleActiveTab}
                toggleOpen={toggleOpen}
                price={product?.price}
              />
            </AppModal>
          </div>
        </div>
    </div>
    <Loader timedOut={isLoading}/>
  </div>)
}

export default RingCustomizerHOC(RingCustomizer);

/*useEffect(() => {
  console.log('>>>>>>LOCATION>>>>>>>>>>>>>>>>>>>>>>>', location);
  console.log('>>>>>>PARAMS>>>>>>>>>>>>>>>>>>>>>>>>>', params);
  console.log('>>>>>>SEARCH PARAMS ENTRIES>>>>>>>>>>', Object.fromEntries(searchParams.entries()));
  console.log('>>>>>>SEARCH PARAMS toString()>>>>>>>', searchParams.toString());
  console.log('>>>>>>SEARCH PARAMS Get>>>>>>>>>>>>>>', searchParams.get('finish_type'));
  return () => console.log('>>>UN MOUNT>>>',)
}, [])*/
