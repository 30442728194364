import React from 'react';
import { getArrangementItems } from './arrangementItems';
import { getFilterMetaData, getSelectedOption, getWarning } from './commonRingUtils';
import { getCategorizedOptions } from './basic';
import { ITab, ITabs } from '../app/types/customizer';
import BoxFilter from '../app/components/BoxFilter';
import SwatchFilter from '../app/components/SwatchFilter';
import SizeFilter from '../app/components/SizeFilter/SizeFilter';
import Summary from '../app/components/Summary';
import { IPageData } from '../app/pages/RingCustomizer/Pages';
import SummaryInfo from '../app/components/SummaryInfo/SummaryInfo';

export const getV2PageData = (props: IPageData) => {
    const { product: productData, subpath, storeData, isBuildFromScratch } = props;
    const product = { ...productData };
    product.categories = getCategorizedOptions('womens', storeData);
    const items: ITabs = [
        {
            title: 'Category',
            eventKey: 'category',
            component: BoxFilter,
            isEnabled: true,
            isSelected: isBuildFromScratch,
            content: {
                hasSwatch: true,
                fieldType: 'category',
                options: product.categories,
                selectedOption: getSelectedOption(product.categories),
            },
        },
        {
            title: 'Style',
            label: 'Style',
            eventKey: 'anniversary_style',
            component: SwatchFilter,
            isEnabled: product.style?.length > 0,
            isSelected: !isBuildFromScratch,
            content: {
                // showDefaultTitle: false,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'style'),
                // warning: getWarning(product, 'style'),
                hasSwatch: true,
                fieldType: 'styles',
                options: product.style,
                selectedOption: getSelectedOption(product.style),
            },
        },
        {
            title: 'Material',
            label: 'Ring Material',
            eventKey: 'material',
            component: SwatchFilter,
            isEnabled: product.ring_material?.length > 0,
            content: {
                // showDefaultTitle: false,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'ring_material'),
                // warning: getWarning(product, 'ring_material'),
                hasSwatch: true,
                fieldType: 'ring_materials',
                options: product.ring_material,
                selectedOption: getSelectedOption(product.ring_material),
            },
        },
        {
            title: 'Arrangement',
            eventKey: 'arrangement',
            items: getArrangementItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Size',
            label: 'Ring Size',
            eventKey: 'ringSize',
            component: SizeFilter,
            isEnabled: product.size?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'size'),
                // warning: getWarning(product, 'size'),
                hasSwatch: false,
                fieldType: 'size',
                options: product.size || [],
                selectedOption: getSelectedOption(product.size),
            },
        },
        {
            title: 'Summary',
            eventKey: 'summary',
            component: Summary,
            isEnabled: subpath !== 'customize',
        },
        {
            title: 'Info',
            eventKey: 'summaryInfo',
            component: SummaryInfo,
            isEnabled: subpath === 'customize',
        },
    ];

    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};
